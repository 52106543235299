@import url("https://fonts.googleapis.com/css2?family=Rubik&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
$bg-color: #073059;
@import url("../component/adminPage/_adminPage.scss");
@mixin GlassBg {
  background: rgba(0, 0, 0, 0.45);
  box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.37);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 10px;
  border: 1px solid rgba(255, 255, 255, 0.18);
}
//will be delete
@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300..900;1,300..900&display=swap");
.palybtn {
  margin-top: 15rem;
  color: rgb(142, 142, 142);
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-family: "Rubik", sans-serif;
  button {
    background-color: rgba(255, 255, 255, 0);
    color: rgb(142, 142, 142);
    margin-right: 0.7rem;
    padding: 0.2rem 1rem;
    border: 1px solid rgb(136, 136, 136);
    &:hover {
      background-color: rgb(142, 142, 142);
      color: #222222;
    }
  }
  p {
    margin-bottom: 0;
  }
}
.willBeRemove {
  position: fixed;
  z-index: 999999;
  min-height: 100vh;
  width: 100%;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  font-size: 1.5rem;
  background-color: #222222;
}
.showErrorsof {
  font-family: "Rubik", sans-serif;
  color: rgb(142, 142, 142);
  margin-top: 1.5rem;
  font-size: 1rem;
  text-align: center;
}
@import url("https://fonts.googleapis.com/css2?family=Lalezar&display=swap");
.secendStep {
  color: rgb(142, 142, 142);
  font-size: 2.2rem;
  font-family: "Lalezar", system-ui;
}
@import url("https://fonts.googleapis.com/css2?family=Sixtyfour&display=swap");
.love {
  font-family: "Sixtyfour", sans-serif;
  font-size: 3rem;
  color: rgb(211, 65, 12);
  text-align: center;
}
.thidOne {
  color: rgb(142, 142, 142);
  font-size: 2rem;
  font-family: "Lalezar", system-ui;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  i {
    color: rgb(3, 91, 163);
    margin-right: 0.5rem;
    margin-bottom: 0.2rem;
  }
}
$primary: #11998e;
$secondary: #38ef7d;
$white: #fff;
$gray: #9b9b9b;
.form__group {
  position: relative;
  padding: 15px 0 0;
  margin-top: 10px;
  width: 50%;
}

.form__field {
  font-family: inherit;
  width: 100%;
  border: 0;
  border-bottom: 2px solid $gray;
  outline: 0;
  font-size: 1.3rem;
  color: $white;
  padding: 7px 0;
  background: transparent;
  transition: border-color 0.2s;

  &::placeholder {
    color: transparent;
  }

  &:placeholder-shown ~ .form__label {
    font-size: 1.3rem;
    cursor: text;
    top: 20px;
  }
}

.form__label {
  position: absolute;
  top: 0;
  display: block;
  transition: 0.2s;
  font-size: 1rem;
  color: $gray;
}

.form__field:focus {
  ~ .form__label {
    position: absolute;
    top: 0;
    display: block;
    transition: 0.2s;
    font-size: 1rem;
    color: $primary;
    font-weight: 700;
  }
  padding-bottom: 6px;
  font-weight: 700;
  border-width: 3px;
  border-image: linear-gradient(to right, $primary, $secondary);
  border-image-slice: 1;
}
@media only screen and (max-width: 576px) {
  .form__group {
    width: 60%;
    margin-top: -10rem;
  }
  .showErrorsof {
    width: 80%;
  }
}
.secendStepp {
  text-align: center;
}
/* reset input */
.form__field {
  &:required,
  &:invalid {
    box-shadow: none;
  }
}
//App
.App {
  position: relative;
  background-color: #eeeeee;
  font-family: "Rubik", sans-serif;
  p,
  h5,
  h4,
  h3 {
    transition: 0.5s;
  }
}
.bgColor {
  top: 0;
  z-index: -100;
  position: absolute;
  width: 100%;
  min-height: 100vh;
  background-color: #dde6ed;
}
// headr part

li {
  list-style-type: none;
}
.header_contaner {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.headerBar {
  display: none;
}
.right-nav {
  display: none !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 1rem 0;
  padding: 0.5rem;
  color: #ffffff;
  height: 4rem;
}
.language {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 0.8rem;
  padding: 0rem 2.5rem;
  margin: 0.5rem 1rem;
  border: 1px solid #ffffff;
  transition: 0.3s;
  cursor: pointer;
  p {
    cursor: pointer;
    margin: 0;
  }
  &:hover {
    color: black;
    background-color: #ffffff3f;
  }
}
.header-top-part {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  justify-content: center;
  justify-items: center;
  padding: 1rem 0;
  background-color: #eeeeee;

  img {
    width: 60%;
  }
}
.header-top-name {
  display: flex;
  flex-direction: column;
  justify-content: center;
  grid-column: 2/5;
  align-items: center;
  font-family: "Lalezar", sans-serif;
  color: black;
}
.azarbaijanLogo {
  a {
    display: flex;
    justify-content: right;
  }
}
.english {
  color: #aeaeae;
}
.navbar {
  justify-content: center;
  @include GlassBg();
  border-radius: 0;
  grid-column: 1/3;
  margin-bottom: 1rem;
  padding: 0.9rem 0.5rem;
  color: #ffffff;
  ul {
    display: flex;
    flex-direction: row;
    margin-bottom: 0;
  }
  li {
    margin-right: 0.2rem;
    cursor: pointer;
    padding: 0.8rem 0.7rem;
    width: 7rem;
    border-radius: 10px;
    transition: 0.3s;
    text-align: center;
    display: flex;
    justify-content: center;
    align-self: center;
    &:hover {
      background-color: #96b6c5;
    }
  }
}
.logo {
  display: flex;
  justify-content: start;
  margin-left: 1rem;
  font-weight: bold;
  font-size: 1.3rem;
  align-items: center;
  text-align: center;
  width: 40%;
  img {
    width: 100%;
  }
}
.footer__logo {
  width: 60%;
}
.errorpage {
  font-size: 5rem;
  font-weight: 900;
  position: fixed !important;
  color: white;
  background: rgba(255, 0, 0, 0.55);
  box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
  backdrop-filter: blur(8.5px);
  -webkit-backdrop-filter: blur(8.5px);
  border: 1px solid rgba(255, 255, 255, 0.18);
  position: absolute;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
  z-index: 99999;
  min-height: 100vh;
  width: 100%;
  background-color: rgba(146, 0, 0, 0.822);
  span {
    font-size: 1rem;
  }
}
.hider {
  display: none;
}
.loadingContainer {
  position: fixed;
  width: 100%;
  height: 100%;
  @include GlassBg();
  background: rgba(0, 0, 0, 0.8);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 0px;
  border: none;
  z-index: 100000;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: #ffffff;
  font-size: 2rem;
  transition: 0.3s;
  .spinner-border {
    width: 5rem;
    height: 5rem;
  }
}
.popupnav {
  position: fixed;
  width: 100%;
  height: 100%;
  @include GlassBg();
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 0px;
  border: none;
  z-index: 100000;
  display: flex;
  flex-direction: column;
  color: #ffffff;
  i {
    display: flex;
    justify-content: right;
    font-size: 2rem;
    padding: 2rem;
  }
  ul {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 0;
    width: 50%;
    margin: 1rem auto;
  }
  li {
    padding: 1rem 0;
    margin: 1rem 0;
    font-size: 1.5rem;
    border: 2px solid #888888;
    border-radius: 5px;
  }
  .activebtn {
    border: none;
    border-radius: 10px;
    border-bottom-left-radius: 20px;
    border-top-right-radius: 20px;
  }
}
// fixed header
.fixed-header {
  position: fixed;
  z-index: 1000;
  top: 0;
  width: 100%;
  @include GlassBg();
  border-radius: 0;
  .right-nav {
    display: none;
  }
  .navbar {
    margin: 0.5rem 50%;
    padding: 0.2rem 0.5rem;
    border-radius: 5px;
    background: none;
    box-shadow: none;
    backdrop-filter: none;
    -webkit-backdrop-filter: none;
    border-radius: none;
    border: none;
    li {
      border-top-right-radius: 20px;
      border-bottom-left-radius: 20px;
      padding: 0.7rem 0;
    }
  }
  a {
    color: #ffffff;
  }
}
.after-scrolled {
  padding-top: 6.5rem;
  .slides-dotes {
    top: 8.5rem;
  }
}
// slider and main section
.slider {
  margin-top: 2rem;
  margin-bottom: 5rem;
}
.carousel-inner {
  border-radius: 5px;
}
.slideShow {
  width: 90%;
  margin: auto;
}
.activebtn {
  background-color: $bg-color;
  color: #dde6ed;
  &:hover {
    color: #ffffff;
  }
}
// news section
.news__title {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-bottom: 1rem;
  border-bottom: 1px solid black;

  div {
    display: flex;
    justify-content: right;
    align-items: center;
    font-size: 1.4rem;
    p {
      margin-bottom: 0;
    }
    i {
      font-size: 1.5rem;
      margin-left: 0.4rem;
      margin-top: 0.4rem;
    }
  }
  button {
    width: 30%;
    //background-color: $bg-color;
    //color: #ffffff;
    border: 3px inset black;
    color: black;
    background: none;
    border-radius: 5px;
    transition: 0.3s;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
    padding: 0.5rem 0;
    &:hover {
      background-color: #4e4e4e;
      color: #ffffff;
      border: 3px solid #4e4e4e;
    }
    i {
      margin-left: 0.2rem;
    }
  }
}
.news__cards {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  padding: 4rem 2rem;
  gap: 5rem;
}
.news__card {
  padding: 2rem;
  display: flex;
  flex-direction: column;
  border: 1px solid rgba(0, 0, 0, 0.174);
  box-shadow: 0px 0px 50px -15px #27374d6c;
  border-radius: 5px;
  transition: 0.2s;
  cursor: pointer;
  background-color: #fdfdfd;
  text-align: center;
  img {
    width: 100%;
    padding: 0.2rem;
    border-radius: 10px;
  }
  button {
    width: 100%;
    border: none;
    background-color: $bg-color;
    color: #ffffff;
    padding: 0.7rem 0;
    font-size: 1.2rem;
    border-radius: 10px;
    &:hover {
      background-color: #27374d;
    }
  }
  &:hover {
    box-shadow: 0px 0px 50px -12px #27374d;
  }
}
.card__img-div {
  overflow: hidden;
  height: 13rem;
  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
.news_cardBtn {
  padding: 2rem 1.5rem;
  button {
    border-radius: 10px;
    i {
      margin-left: 0.5rem;
    }
  }
}
.news__info {
  display: flex;
  flex-grow: 1;
  justify-content: center;
  align-items: center;
  height: 8rem;
  h5 {
    text-align: center;
  }
}
.rep-container {
  display: grid;
  gap: 2rem;
  grid-template-columns: repeat(1, 1fr);
}
// footer
.Footer {
  @include GlassBg();
  background: rgba(0, 0, 0, 0.65);
  border-radius: 0;
  color: #ffffff;
}
.footer__container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  justify-content: center;
  margin-top: 2rem;
}
.footer__info {
  display: flex;
  flex-direction: column;
  padding: 0.5rem;
  grid-column: 3/5;
  text-align: right;
  margin: 0 1rem;
}
.phone {
  margin: 1rem 0;
}
.footer__logo {
  text-align: right;
  display: flex;
  justify-content: right;
  width: 100%;
  a {
    display: flex;
    justify-content: right;
  }
  img {
    width: 80%;
    margin-top: 1rem;
  }
}
.footer-location {
  grid-column: 1/3;
  padding: 0.5rem;
  img {
    border-radius: 5px;
    width: 100%;
  }
}
.footerItem {
  h5 {
    text-align: right;
    margin: 0 1rem;
  }
}
// products

.Products {
  padding-bottom: 5rem;
  min-height: 90vh;
  .container {
    padding: 4rem 0;
  }
}
.products__items {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 5rem;
  padding-top: 5rem;
  padding-right: 1rem;
}
.products__title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 1rem 1rem 1rem 0;
  border-bottom: 2px solid rgba(0, 0, 0, 0.43);
  border-top: 2px solid rgba(0, 0, 0, 0.43);
  //background-color: #0c356a;
  //color: #ffffff;
  color: black;
}
// rep section
.represent {
  .container {
    padding: 5rem 0;
  }
}

.rep__carts {
  text-align: right;
}
.rep__title {
  display: flex;
  flex-direction: row;
  grid-column: 2/4;
  justify-content: right;
  padding: 1rem 0;
  p {
    font-size: 1.3rem;
  }
}
.repCart {
  background-color: #e5e5e5e9;
  border-radius: 5px;
  padding: 2rem;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  transition: 0.3s;
  border-right: 3px solid #0c356a;
  &:hover {
    box-shadow: 0px 10px 25px 5px rgba(0, 0, 0, 0.1);
  }
}
.rep__phone {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding-left: 5rem;
  padding-right: 2rem;
  justify-content: space-between;
  i {
    background-color: #27374d;
    color: #ffffff;
    cursor: pointer;
    padding: 0.6rem 0.7rem;
    opacity: 0;
  }
}
.rep__info {
  padding: 1rem 0;
  grid-column: 1/4;
}
// about section
.about-us {
  text-align: right;
  .container {
    padding: 5rem 0;
  }
}
.about__header {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  padding-right: 2rem;
  border-right: 2px dotted black;
  h4 {
    display: inline;
    margin-bottom: 2rem;
    padding: 1rem 0;
    border-bottom: 2px solid $bg-color;
  }
  p {
    margin-top: 4rem;
  }
  img {
    width: 90%;
    justify-content: right;
    padding: 1rem;
  }
}
.about-img-top {
  position: relative;
  .dotted {
    position: absolute;
    width: 25%;
    rotate: -90deg;
    bottom: 9rem;
    left: 2.45rem;
  }
}
.about-img-bottom {
  position: relative;
  grid-column: 3/5;
  margin-right: 2rem;
  .dotted {
    position: absolute;
    width: 25%;
    rotate: 90deg;
    bottom: -1.8rem;
    right: -1.8rem;
  }
}
.about_text {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  padding: 4rem 0;
  padding-right: 1rem;
  border-right: 2px dotted black;
  p {
    margin: 1rem 0;
    grid-column: 1/3;
  }
  img {
    grid-column: 3/5;
    width: 90%;
    margin-left: 3rem;
  }
}
.dot {
  position: absolute;
  display: inline;
  font-size: 4rem;
  color: #073059;
  user-select: none;
}
.Slides {
  position: relative;
}
.slides-dotes {
  width: 1.6%;
  position: absolute;
  right: 5.5rem;
  top: 0rem;
}
// ProductPage
.product-page {
  position: relative;
  .container {
    padding: 5rem 0;
  }
}
.productBackbtn {
  position: absolute;
  display: flex;
  align-items: center;
  font-size: 1rem;
  background-color: #073059;
  color: #ffffff;
  padding: 0.2rem 0.5rem;
  border-radius: 5px;
  top: 1rem;
  left: 10.5rem;
  cursor: pointer;
  i {
    margin-right: 0.3rem;
  }
  &:hover {
    background-color: #336da8;
  }
}
.product-page__top {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
}
.product-img {
  grid-column: 1/5;
  display: flex;
  justify-content: center;
  margin-bottom: 2rem;
  img {
    width: 80%;
  }
}
.productpageinfo {
  text-align: right;
}
.product-dis {
  margin-top: 3rem;
  grid-column: 5/7;
  text-align: right;
  display: flex;
  flex-direction: column;
  justify-content: right;
  h2 {
    padding-bottom: 2rem;
  }
}
.detail-selecter {
  display: flex;
  flex-direction: row;
  background-color: #073059;
  color: #ffffff;
  justify-content: end;
  align-items: center;
  p {
    padding: 2rem;
    margin: 0;
    &:hover {
      background-color: #0c356a;
    }
  }
}
.product-moreInfo {
  margin: 2rem 0;
}
.album-container {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1rem;
  img {
    width: 100%;
    cursor: pointer;
  }
}
.productActive {
  background-color: #27374d;
}
// All News
.allNews {
  position: relative;
  .container {
    padding: 5rem 0;
  }
}
//NewsPage
.newsPage {
  position: relative;
  .container {
    padding: 5rem 0;
  }
}
.news-container {
  display: grid;
  grid-template-columns: repeat(8, 1fr);
}
.anotherNews {
  grid-column: 7/9;
}
.anotherNews {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  .news__card {
    margin-bottom: 1rem;
  }
}
.newsInfo {
  grid-column: 1/7;
  text-align: right;
  overflow: hidden;
  img {
    margin-bottom: 1rem;
  }
}
.newsInfo-details {
  h3 {
    padding: 1.5rem 0;
  }
}
// All news card
.allNews-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 3rem;
  padding: 0 1rem;
}
// Form
.enlastline {
  text-align-last: right;
}
.Form {
  .container {
    padding: 5rem 0;
  }
}
.form-container {
  display: grid;
  grid-template-columns: repeat(1, 1fr);
}
.forms-right {
  display: grid;
  text-align: center;
  ul {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    a:first-child {
      grid-column: 1/3;
    }
  }
  a {
    text-decoration: none;
  }
  li {
    padding: 2.5rem 0;
    margin: 1rem 1rem;
    border: 2px dotted black;
    color: #fff;
    font-weight: bold;
    @include GlassBg();
    background: #2e3f4f;
    border-radius: 5px;
    cursor: pointer;
    font-size: 1.2rem;
    font-size: 1.5rem;
    &:hover {
      background: rgba(70, 69, 69, 0.65);
      box-shadow: 0 8px 32px 0 rgba(0, 0, 0, 0.57);
    }
    transition: 0.3s;
  }
}
.forms-left {
  grid-column: 1/7;
  border-right: 1px solid black;
  h3 {
    text-align: right;
    margin: 0.2rem 2rem;
    padding-bottom: 1rem;
    border-bottom: 2px dotted black;
  }
  form {
    display: grid;
    padding: 2rem;
    grid-template-columns: 1fr;
    text-align: right;
    input {
      border: none;
      text-align: right;
      padding: 0.1rem 0.2rem;
    }
    label {
      margin-bottom: 0.3rem;
    }
  }
}
.question {
  margin-top: 2rem;
  margin-bottom: 3rem;
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  p {
    font-size: 1.2rem;
  }
}
.question-select {
  display: flex;
  flex-direction: row;
  justify-content: center;
  label {
    display: flex;
    justify-content: center;
    background-color: #27374d;
    width: 15%;
    margin-left: 1rem;
    padding: 0.7rem 0;
    color: #ffffff;
    cursor: pointer;
    border-radius: 10px;
    input:checked {
      background-color: red;
    }
  }
  input {
    cursor: pointer;
    padding: 1rem;
    margin-right: 0.2rem;
    accent-color: rgb(0, 145, 255);
    border: none;
    box-shadow: none;
  }
  input:checked {
    & {
      background-color: red;
    }
  }
}
.tireSurvey-form {
  button {
    margin: auto;
    width: 80%;
    margin-top: 2rem;
    padding: 0.8rem;
    &:hover {
      background-color: #336da8;
    }
  }
}
//forms --- Sale form

.sform-names {
  display: grid;
  margin-bottom: 1.5rem;
  grid-template-columns: repeat(3, 1fr);
  gap: 0.8rem;
  div {
    display: flex;
    flex-direction: column;
    margin-left: 1em;
  }
}
.sform-phone {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-bottom: 1.5rem;
  div {
    display: flex;
    flex-direction: column;
    margin: 0.2rem;
  }
  .birthDate {
    display: flex;
    flex-direction: row;
    grid-column: 3/3;
  }
  .buyDate {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column: 1/3;
    margin: 0;
    align-items: end;
    label {
      text-align: left;
    }
  }
  .year,
  .month,
  .day {
    input {
      width: 100%;
    }
  }
  .phoneNumber {
    grid-column: 1/3;
    width: 50%;
    margin: auto;
  }
}
.sform-moredata {
  text-align: right;
}
.sform-moredata {
  width: 60%;
  margin-left: auto;
  margin-bottom: 1.5rem;
}
.sform-submitBtn {
  width: 20%;
  padding: 0.6rem 0;
  border: none;
  border-radius: 10px;
  background-color: #073059;
  color: #ffffff;
  margin-left: auto;
}
.cityandmore {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 2rem;
  margin-bottom: 2rem;
  text-align: right;
  div {
    display: flex;
    flex-direction: column;
  }
  textarea {
    text-align: right;
    padding: 1rem;
  }
}
.about_text {
  .about-bottomText {
    margin-top: 3rem;
    grid-column: 1/8;
  }
}
//Contact-us
.contact-us {
  min-height: 120vh;
  height: 100%;
  .contact-us_container {
    margin: 4rem 10rem;
    position: relative;
  }
  .locate {
    position: absolute;
    width: 5%;
    top: 11.8rem;
    left: 34.1rem;
  }
  .contact-location {
    width: 100%;
    border-radius: 10px;
    box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.61);
  }
  .contact-us_info {
    position: absolute;
    right: 0;
    text-align: right;
    padding: 1rem;
    color: #ffffff;
    background: rgba(0, 0, 0, 0.45);
    box-shadow: 0 8px 32px 0 rgba(31, 38, 135, 0.37);
    backdrop-filter: blur(5px);
    -webkit-backdrop-filter: blur(5px);
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    height: 100%;
    width: 30%;

    .contact-address {
      margin: 2rem 0;
    }
    .contact-ways {
      margin-top: 5rem;
    }
  }
  .contact-location {
    border: 1px solid black;
  }
}
.newsImages {
  margin: 5rem 0;
  padding-top: 3rem;
  border-top: 1px solid black;
}
.news_images-container {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1rem;
  div {
    overflow: hidden;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
      cursor: pointer;
      &:hover {
        transform: scale(1.15);
      }
    }
  }
}
.product_table {
  display: flex;
  justify-content: center;
  width: 100%;
  margin-top: 5rem;
  tbody,
  tr,
  td {
    border: 1px dotted black;
  }
  td {
    padding: 1rem;
    text-align: center;
  }
  thead {
    background-color: #0c356a;
    color: #ffffff;
    text-align: center;
    th {
      padding: 1rem;
    }
  }
  tr {
    &:hover {
      background-color: #d8d8d8;
    }
  }
}
.album-container {
  div {
    overflow: hidden;
    img {
      height: 100%;
      object-fit: cover;
      transition: transform 0.5s ease;
      &:hover {
        transform: scale(1.2);
      }
    }
  }
}
.catalog {
  display: grid;
  gap: 1rem;
  margin: 0 1rem;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  margin-top: 3rem;
  a {
    transition: 0.3s;
    border-radius: 10px;
    padding: 1rem 2rem;
    background-color: #336da8;
    color: #ffffff;
    text-decoration: none;
    &:hover {
      background-color: #0c356a;
    }
  }
}
.carousel-item {
  overflow: hidden;
  img {
    transition: transform 0.5s ease;
    &:hover {
      transform: scale(1.1);
    }
  }
}
.popImg {
  position: fixed;
  display: flex;
  justify-content: center;
  flex-direction: column;
  width: 100%;
  height: 100%;
  @include GlassBg();
  background: rgba(0, 0, 0, 0.5);
  backdrop-filter: blur(15px);
  -webkit-backdrop-filter: blur(15px);
  border-radius: 0px;
  border: none;
  z-index: 999999;
  color: #ffffff;
  .imgcontainer {
    display: flex;
    justify-content: center;
    height: 80%;
  }
  img {
    height: 100%;
  }
  i {
    font-size: 2.5rem;
    text-align: right;
    margin: 1rem 5rem;
    cursor: pointer;
  }
}
.englishVersion {
  h3,
  h2 {
    text-align: left;
  }
  .product-page {
    li {
      text-align: left;
    }
    h2 {
      margin-left: 1rem;
    }
  }
  .news__info {
    h5 {
      text-align: center;
    }
  }
  .contact-us_info {
    left: 0;
    border-top-right-radius: 0px;
    border-bottom-right-radius: 0px;
    border-top-left-radius: 5px;
    border-bottom-left-radius: 5px;
  }
  .news__info {
    margin: 1rem 0;
  }
  .detail-selecter {
    justify-content: left;
  }
}

//Responsive and media queries
@media only screen and (max-width: 1200px) {
  .news__cards {
    grid-template-columns: repeat(2, 1fr);
  }
  .footer__container {
    grid-template-columns: repeat(2, 1fr);
  }
  .about__header {
    h4 {
      font-size: 1.2rem;
    }
  }
  .about-img-top {
    overflow: hidden;
    .topImgAbout {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .catalog {
    grid-template-columns: repeat(2, 1fr);
  }
  .about-img-bottom {
    overflow: hidden;

    .aboutsecendImg {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .products__items {
    grid-template-columns: repeat(2, 1fr);
  }
  .album-container {
    grid-template-columns: repeat(3, 1fr);
  }
}
@media only screen and (max-width: 576px) {
  header {
    z-index: 1000;
  }
  .news__cards {
    display: grid;
    grid-template-columns: 1fr;
    padding: 4rem 2rem;
    gap: 2rem;
  }
  .news__title {
    padding-bottom: 0.5rem;
    button {
      width: 80%;
      padding: 0.5rem 0.5rem;
      font-size: 0.8rem;
    }
  }
  .Slides {
    border-radius: 0px;
    .container {
      width: 100%;
    }
  }
  .slideShow {
    margin: 0;
    width: 100%;
  }
  .carousel-inner {
    border-radius: 0px;
  }
  .header_contaner {
    grid-template-columns: 1fr;
    width: 100%;
    @include GlassBg();
    border-radius: 0px;
  }
  .right-nav {
    display: grid !important;
    margin: 0;
    width: 100%;
    border-radius: 0px;
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    background: none;
    box-shadow: none;
    border: none;
    backdrop-filter: none;
    a {
      color: #ffffff;
    }
  }
  .language {
    margin: 0;
    padding: 0.5rem 0;
    width: 70%;
  }
  .header-top-part {
    display: none;
    grid-template-columns: repeat(2, 1fr);
    a {
      width: 80%;
    }
  }
  .azarbaijanLogo {
    a {
      justify-content: center;
    }
    grid-row: 2/2;
    grid-column: 2/3;
  }
  .tractor-logo {
    a {
      display: flex;
      justify-content: center;
    }
  }
  .header-top-name {
    margin: 1rem 0;
  }
  .header-top-name {
    grid-column: 1/3;
  }
  .logo {
    margin: 0;
    width: 100%;
    text-align: center;
    img {
      width: 70%;
    }
  }
  .navbar {
    display: none;
  }
  .headerBar {
    display: block;
    grid-column: 4/5;
    text-align: right;
    font-size: 2rem;
    padding-right: 0.5rem;
  }
  .fa-phone {
    font-size: 1.8rem;
    margin-right: 1rem;
  }
  .about__header {
    grid-template-columns: 1fr;
    border: none;
    padding: 0 1rem;
    img {
      width: 100%;
      margin: auto;
    }
    h4 {
      font-size: 1.2rem;
    }
  }
  .about-img-top {
    grid-row: 2/2;
    padding-top: 1rem;
    .dotted {
      top: 1.9rem;
      left: -0.9rem;
    }
  }
  .about_text {
    grid-template-columns: 1fr;
    border: none;
    padding: 1rem 1rem;
    p {
      margin: 1rem 0;
      grid-column: 1/1;
    }
    img {
      grid-column: 1/1;
      width: 90%;
      margin-left: 3rem;
    }
  }
  .about_secendImg {
    display: none;
  }
  .about-us {
    width: 95%;
    margin: auto;
  }
  .allNews-container {
    grid-template-columns: 1fr;
    padding: 1rem 2rem;
  }
  .news__title {
    button {
      width: 85%;
    }
    div {
      font-size: 1.2rem;
    }
  }
  .header_contaner {
    padding: 0.8rem 0;
  }
  .products__items {
    grid-template-columns: 1fr;
    padding: 3rem 2rem;
    gap: 2rem;
  }
  .rep__carts {
    grid-template-columns: 1fr;
    padding: 1rem 2rem;
  }
  .fixed-header {
    position: fixed;
    z-index: 1000;
    width: 100%;
    @include GlassBg();
    border-radius: 0;
    .right-nav {
      display: grid;
      width: 100%;
    }
    .navbar {
      display: none;
    }
  }
  .footer__container {
    display: grid;
    grid-template-columns: 1fr;
  }
  .footer__info {
    display: flex;
    justify-content: center;
  }
  .footer-location {
    display: flex;
    justify-content: center;
    margin: 0;
    grid-column: 3/5;
    grid-row: 3/3;
  }
  .footer__logo {
    display: none;
  }
  .news-container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
  .newsInfo {
    grid-column: 1/1;
    grid-row: 1/1;
    text-align: right;
    img {
      width: 100%;
      object-fit: cover;
    }
  }
  .anotherNews {
    grid-column: 1/1;
    grid-row: 2/2;
    margin: 2rem;
    padding-top: 2rem;
  }
  .productBackbtn {
    top: 1.5rem;
    left: 1rem;
  }
  .form-container {
    grid-template-columns: 1fr;
  }
  .forms-left {
    grid-column: 1/1;
    grid-row: 2/2;
    border: none;
  }
  .forms-right {
    margin-bottom: 2rem;
    ul {
      grid-template-columns: 1fr;
      padding: 0;
      width: 90%;
      margin: auto;
      gap: 0.5rem;
      a:first-child {
        grid-column: 1/1;
      }
    }
    a {
      margin: 0.8rem 0;
    }
    li {
      padding: 1.5rem 0;
      width: 100%;
      margin: 0;
    }
  }
  .question-select {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    //gap: 1rem 0;
    label:first-child {
      grid-column: 2/2;
      grid-row: 2/2;
    }
    label:last-child {
      grid-column: 1/1;
      grid-row: 1/1;
    }
    label {
      //padding: 0.1rem 1rem;
      width: 80%;
      margin: auto;
    }
  }
  .cityandmore {
    display: grid;
    grid-template-columns: 1fr;
  }
  .sform-names {
    grid-template-columns: 1fr;
    div {
      margin-top: 1rem;
    }
    div:last-child {
      grid-row: 1/1;
    }
    div:first-child {
      grid-row: 3/3;
    }
  }
  .sform-phone {
    grid-template-columns: 1fr;
    margin-top: 0.8rem;
    gap: 2rem;
    .birthDate {
      grid-column: 1/1;
    }
    .phoneNumber {
      grid-column: 1/1;
      grid-row: 2/2;
      width: 100%;
    }
  }
  .sform-moredata {
    width: 100%;
  }
  .sform-submitBtn {
    width: 50%;
  }
  .contact-us {
    min-height: 120vh;
    .contact-us_container {
      display: grid;
      grid-template-columns: 1fr;
      margin: 1rem;
      margin-top: 5rem;
    }
    .contact-us_info {
      position: relative;
      width: 100%;
      background: rgba(0, 0, 0, 0.75);
      border-top-right-radius: 0px;
      border-top-left-radius: 0px;
      border-bottom-left-radius: 5px;
    }
    a {
      width: 100%;
      height: 40vh;
      grid-row: 1/1;
      grid-column: 1/1;
    }
    .locate {
      width: 10%;
      left: 10rem;
      top: 7.5rem;
    }
    .contact-location {
      object-fit: cover;
      height: 100%;
      border-bottom-left-radius: 0px;
      border-bottom-right-radius: 0px;
      border: 2px dotted black;
      border-bottom: none;
      padding: 0.1rem;
    }
  }
  .product-page__top {
    grid-template-columns: 1fr;
  }
  .product-img {
    padding: 0 0.3rem;
    grid-column: 1/1;
    img {
      width: 100%;
    }
  }
  .product-dis {
    grid-column: 1/1;
    grid-row: 2/2;
    margin: 1.5rem;
  }
  .album-container {
    margin: 0.5rem;
    grid-template-columns: 1fr;
  }
  .news_images-container {
    grid-template-columns: 1fr;
  }
  .rep-container {
    grid-template-columns: 1fr;
    padding: 0 1rem;
  }
  .rep__carts {
    width: 100%;
    padding: 0;
    margin: 0;
  }
  .product_table {
    padding: 0 1rem;
  }
  .popImg {
    .imgcontainer {
      padding: 1rem;
      display: flex;
      justify-content: center;
      height: auto;
      width: 100%;
    }
    img {
      width: 100%;
      object-fit: contain;
    }
    i {
      margin: 1rem;
    }
  }
  .slides-dotes {
    display: none;
  }
  .about-img-bottom {
    grid-column: 1/1;
    .dotted {
      bottom: -1rem;
    }
  }
  .repCart {
    grid-template-columns: repeat(1, 1fr);
    border-right: none;
    border-top: 2px solid #0c356a;
  }
  .rep__info {
    grid-column: 1/1;
    grid-row: 2/2;
  }
  .rep__phone {
    grid-column: 1/1;
    padding: 1rem 0;
  }
  .rep__title {
    grid-column: 1/1;
    grid-row: 1/1;
  }
}
@media only screen and (max-width: 335px) {
  .right-nav {
    grid-template-columns: repeat(3, 1fr);
  }
}
